import { Container, FormControl, FormLabel, Grid, MenuItem, Select, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SettingActions from '../../store/setting/setting.actions';
import * as UserActions from '../../store/user/user.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import TitleBar from '../base/TitleBar';
import DepositDetail from './DepositDetail';
import DepositList from './DepositList';

const styles = theme => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(7),
        right: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
});

class DepositContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'list',
            selectedId: '',
            selectedItem: {},
            coinType: undefined,
            user: undefined
        };

        this.userChanged = this.userChanged.bind(this);
    }

    componentDidMount() {
        this.props.actions.SettingActions.FetchDeposits('', '', moment().format());
        this.props.actions.UserActions.LoadUsers();
    }

    userChanged(user) {
        this.setState({
            mode: "details",
            selectedItem: user,
        })
    }

    render() {
        const { classes } = this.props;

        const tenants = {}

        this.props.users.forEach((x) => {
            tenants[x.id] = x.tenantName;
        })
        return (
            <div>
                <NavBar
                    pageName="Dashboard"
                    children={
                        <Container maxWidth={false} >
                            <TitleBar heading="Deposits" secondary={"List of Deposits"}
                                icon={<HelpOutlineIcon />} />
                            <Grid container spacing={2} >
                                <Grid item md={6}>
                                    <FormControl className={classes.formControl}>
                                        <FormLabel component="legend">Coin Type</FormLabel>
                                        <Select
                                            placeholder="Select Coin Type"
                                            name="coinType"
                                            value={this.state.coinType}
                                            onChange={(e) => {
                                                this.setState({
                                                    coinType: e.target.value
                                                });
                                                this.props.actions.SettingActions.FetchDeposits(e.target.value, this.state.user, moment().format());
                                            }}
                                            fullWidth
                                            labelId="coinType"
                                            id="coinType"
                                        >
                                            <MenuItem value={undefined}>Select Coin Type</MenuItem>
                                            {
                                                ["TRX", "BTC", "BCH", "LTC", "XRP", "ETH", "BNB"].map((x) => {
                                                    return <MenuItem value={x}>{x}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl className={classes.formControl}>
                                        <FormLabel component="legend">User</FormLabel>
                                        <Select
                                            name="user"
                                            value={this.state.user}
                                            onChange={(e) => {
                                                this.setState({
                                                    user: e.target.value
                                                });
                                                this.props.actions.SettingActions.FetchDeposits(this.state.coinType, e.target.value, moment().format());
                                            }}
                                            fullWidth
                                            labelId="user"
                                            id="user"
                                        >
                                            <MenuItem value={undefined}>Select User</MenuItem>
                                            {
                                                this.props.users.map((x) => {
                                                    return <MenuItem value={x.id}>{x.tenantName}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12}>
                                    <DepositList
                                        deposits={this.props.deposits}
                                        onDetailsOpen={(user) => this.userChanged(user)}
                                        users={this.props.users} />
                                    <DepositDetail
                                        open={this.state.mode === "details"}
                                        deposit={this.state.selectedItem}
                                        tenants={tenants}
                                        users={this.props.users}
                                        onClose={() =>
                                            this.setState({
                                                mode: "list",
                                                selectedItem: {}
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    }
                />
                <Footer />
            </div >
        );
    }
}


const mapStateToProps = (response) => ({
    isLoading: response.SettingReducer.isLoading,
    users: response.UserReducer.Users,
    deposits: response.SettingReducer.deposits
});

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            SettingActions: bindActionCreators(SettingActions, dispatch),
            UserActions: bindActionCreators(UserActions, dispatch),
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DepositContainer));

