import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif'
    ].join(','),
    fontWeightBold: 700,
    fontWeightLight: 400,
    fontWeightMedium: 600,
    fontWeightRegular: 500
  },
  overrides: {
    MuiTextField: {
      variant: 'outlined'
    },
    MuiLinearProgress: {
      root: {
        height: 10
      }
    },
    MUIDataTable: {
      root: {
        backgroundColor: "#bbe1fa"
      },
      paper: {
        boxShadow: "none"
      }
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: '#f0efef',
        padding: 16
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: '#f4f6ff'
        }
      }
    },
    MUIDataTableHeadCell: {
      fixedHeaderCommon: {
        backgroundColor: "#3282b8",
        color: "white"
      }
    },
    MuiTableFooter: {
      root: {
        backgroundColor: '#f0efef'
      }
    },
    MuiChip: {
      root: {
        color: "white"
      }
    }
  },
  palette: {
    primary: {
      main: '#273b7a',
      dark: '#4a8cac'
    },
    secondary: {
      main: '#3282b8',
    },
    success: {
      main: '#edffea',
      dark: "#79d70f"
    },
    info: {
      main: '#00aced',
    },
    error: {
      main: '#c70039',
    },
    divider: '#bfbdbd',
    background: {
      default: '#fff',
    },
  },
});

export default theme;
