import {call, put, takeEvery} from 'redux-saga/effects';
import * as AuthActions from './auth.actions';
import AuthAPI from './auth.api';
import {AuthenticationActionTypes} from './auth.model';

export function* handleLogin( action ) {
  try {
    const response = yield call( AuthAPI.Login, action.request );
    yield put( AuthActions.loginTriggers.success( response ) );
  } catch ( e ) {
    yield put( AuthActions.loginTriggers.error( e ) );
  }
}

export function* handleLogout( action ) {
  try {
    const response = yield call( AuthAPI.Logout, action.request );
    yield put( AuthActions.logoutTriggers.success( response ) );
  } catch ( e ) {
    yield put( AuthActions.logoutTriggers.error( e ) );
  }
}

export function* AuthSaga() {
  yield takeEvery( AuthenticationActionTypes.LOGIN_REQUEST, handleLogin );
  yield takeEvery( AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout );
}

