import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as SettingActions from '../../store/setting/setting.actions';
import * as UserActions from '../../store/user/user.actions';
import ViewUser from './View.User';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import AddUser from './Add.user';
import ViewUserDetails from './View.UserDetails';
import { getCoinBalances, getCoinPrices } from '../../helpers/coinHelper';

const styles = theme => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(7),
        right: theme.spacing(2),
    },
});

class UserContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'list',
            selectedId: '',
            selectedItem: {},
            coinPrices: [],
            coinBalance: []
        };

        this.userChanged = this.userChanged.bind(this);
    }

    componentDidMount() {
        this.props.actions.userActions.LoadUsers();

        getCoinPrices().then((x) => [
            this.setState({
                coinPrices: x
            })
        ])
    }

    userChanged(user) {
        this.setState({
            mode: "details",
            selectedItem: user,
            coinBalance: []
        })
        getCoinBalances(user.wallets).then((x) => {
            this.setState({
                coinBalance: x
            })
        });
    }


    render() {
        const { classes } = this.props;

        return (
            <div>
                <NavBar
                    pageName="Customers"
                    children={
                        <Container maxWidth={false} className={classes.root}>
                            <ViewUser
                                users={this.props.users}
                                onDetailsOpen={(user) => this.userChanged(user)}
                            />
                            <Fab size="small" color="secondary" aria-label="add" className={classes.fab}>
                                <AddIcon onClick={() => this.setState({ mode: "add" })} />
                            </Fab>
                            <AddUser
                                onSave={(data) => {
                                    this.props.actions.userActions.AddUser(data);
                                    this.setState({
                                        mode: "list"
                                    });
                                }}
                                open={this.state.mode === "add"}
                                onClose={() => this.setState({ mode: "list" })}
                            />
                            <ViewUserDetails
                                open={this.state.mode === "details"}
                                user={this.state.selectedItem}
                                coinPrices={this.state.coinPrices}
                                coinBalance={this.state.coinBalance}
                                onClose={() =>
                                    this.setState({
                                        mode: "list",
                                        selectedItem: {}
                                    })
                                }
                            />
                        </Container>
                    }
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (response) => ({
    isLoading: response.UserReducer.isLoading,
    users: response.UserReducer.Users,
});

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            SettingActions: bindActionCreators(SettingActions, dispatch),
            userActions: bindActionCreators(UserActions, dispatch)
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserContainer));

