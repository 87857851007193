import { SettingActionTypes } from './setting.model';
import * as Toastr from 'toastr';
import '../../assets/css/toastr.min.css';
require('dotenv').config();

export const initialState = {
    setting: {},
    adminSetting: {},
    isLoading: false,
    transactions: [],
    deposits: [],
    withdraws: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SettingActionTypes.FETCH_ADMIN_REQUEST:
        case SettingActionTypes.FETCH_TRANSACTIONS_REQUEST:
        case SettingActionTypes.UPDATE_SETTING_REQUEST:
        case SettingActionTypes.FETCH_DEPOSITS_REQUEST:
        case SettingActionTypes.FETCH_WITHDRAWS_REQUEST:
        case SettingActionTypes.FETCH_SETTING_REQUEST:
            {
                return Object.assign({}, state, {
                    isLoading: true
                });
            }

        case SettingActionTypes.FETCH_TRANSACTIONS_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                transactions: action.response,
            });
        }

        case SettingActionTypes.FETCH_WITHDRAWS_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                withdraws: action.response,
            });
        }

        case SettingActionTypes.FETCH_DEPOSITS_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                deposits: action.response,
            });
        }

        case SettingActionTypes.FETCH_ADMIN_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                adminSetting: action.response,
            });
        }

        case SettingActionTypes.FETCH_SETTING_SUCCESS: {
            return Object.assign({}, state, {
                isLoading: false,
                setting: action.response
            });
        }

        case SettingActionTypes.UPDATE_SETTING_SUCCESS: {
            Toastr.success("Settings Updated Successfully")
            return Object.assign({}, state, {
                isLoading: false,
                setting: action.response
            });
        }

        case SettingActionTypes.FETCH_ERROR: {
            return Object.assign({}, state, {
                isLoading: false
            });
        }

        default: {
            return state;
        }
    }
};

export { reducer as SettingReducer };