import {AuthenticationActionTypes} from './auth.model';

export const loginTriggers = {
    request: request => ( {type: AuthenticationActionTypes.LOGIN_REQUEST, request} ),
    success: response => ( {type: AuthenticationActionTypes.LOGIN_SUCCESS, response} ),
    error: error => ( {type: AuthenticationActionTypes.LOGIN_FAILURE, error} )
};

export function Login( email, password ) {
    return function ( dispatch ) {
        dispatch( loginTriggers.request( {
            email: email,
            password: password
        } ) );
    };
}

export const logoutTriggers = {
    request: request => ( {type: AuthenticationActionTypes.LOGOUT_REQUEST, request} ),
    success: response => ( {type: AuthenticationActionTypes.LOGOUT_SUCCESS, response} ),
    error: error => ( {type: AuthenticationActionTypes.LOGOUT_FAILURE, error} )
};

export function Logout( id ) {
    return function ( dispatch ) {
        dispatch( logoutTriggers.request( id ) );
    };
}
