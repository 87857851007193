import { InputAdornment, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode';
import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getBlockChainUrl } from '../../helpers/utility';
import * as Toastr from 'toastr';
import '../../assets/css/toastr.min.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: "center",
        marginBottom: theme.spacing(1)
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

export default function WalletInfo(props) {
    const classes = useStyles();
    setTimeout(() => {
        var canvas = document.getElementById(props.wallet.walletId)

        if (canvas) {
            QRCode.toCanvas(canvas, props.wallet.walletId, { width: 150 }, function (error) {
                if (error) console.error(error)
                console.log('success!');
            });
        }
    }, 200);

    // let balance = props.coinBalance.filter((x) => x.CoinType.toUpperCase() === props.wallet.currency.toUpperCase());
    // let total = 0;
    // if (balance.length > 0) {
    //     let price = props.coinPrices.filter((x) => x.symbol.toUpperCase() === props.wallet.currency.toUpperCase());
    //     balance = balance[0].CoinBalance;
    //     if (price.length > 0) {
    //         total = (balance * price[0].price).toFixed(3);
    //     }
    //     else {
    //         balance = 0;
    //     }
    // }
    // else {
    //     balance = 0;
    // }

    function CopyMe(id) {
        /* Get the text field */
        var copyText = document.getElementById(id);

        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        Toastr.success(copyText.value, "Copied to clipboard");
    }

    return (
        <Card elevation={10} className={classes.root}>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                        {props.wallet.currency}
                    </Typography>
                    <Typography component="h5" variant="h6">
                        <a  rel="noopener noreferrer" href={getBlockChainUrl(props.wallet.currency) + props.wallet.address} target="_blank">Coin Information</a>
                    </Typography>
                    <TextField
                        fullWidth
                        id={props.wallet.address}
                        value={props.wallet.address}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FileCopyIcon color="secondary" onClick={() => CopyMe(props.wallet.address)} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </CardContent>
            </div>
            <canvas id={props.wallet.walletId}></canvas>

        </Card>
    );
}