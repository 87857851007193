import { Container, FormControl, FormLabel, Grid, MenuItem, Select, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SettingActions from '../../store/setting/setting.actions';
import SettingAPI from '../../store/setting/setting.api';
import * as UserActions from '../../store/user/user.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import TitleBar from '../base/TitleBar';
import ReportList from './ReportList';

const styles = theme => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(7),
        right: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
});

class ReportContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: []
        };
    }

    componentDidMount() {
        SettingAPI.GetReports().then((x) => {
            this.setState({
                reports: x.entries
            })
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <NavBar
                    pageName="Dashboard"
                    children={
                        <Container maxWidth={false} >
                            <TitleBar heading="Reports" secondary={"List of Reports"}
                                icon={<HelpOutlineIcon />} />
                            <Grid container spacing={2} >
                                <Grid item md={12}>
                                    <ReportList reports={this.state.reports ? this.state.reports : []} />
                                </Grid>
                            </Grid>
                        </Container>
                    }
                />
                <Footer />
            </div >
        );
    }
}

export default withStyles(styles)(ReportContainer);

