import { UserActionTypes } from './user.model';

export const loadUserTriggers = {
    request: request => ({ type: UserActionTypes.FETCH_REQUEST, request }),
    success: response => ({ type: UserActionTypes.FETCH_SUCCESS, response }),
    error: error => ({ type: UserActionTypes.FETCH_ERROR, error })
};

export function LoadUsers() {
    return function (dispatch) {
        dispatch(loadUserTriggers.request());
    };
}

export const loadUserDetailTriggers = {
    request: request => ({ type: UserActionTypes.DETAIL_REQUEST, request }),
    success: response => ({ type: UserActionTypes.DETAIL_SUCCESS, response }),
    error: error => ({ type: UserActionTypes.FETCH_ERROR, error })
};

export function LoadUserDetail(id) {
    return function (dispatch) {
        dispatch(loadUserDetailTriggers.request(id));
    };
}

export const addUserTriggers = {
    request: request => ({ type: UserActionTypes.ADD_REQUEST, request }),
    success: response => ({ type: UserActionTypes.ADD_SUCCESS, response }),
    error: error => ({ type: UserActionTypes.FETCH_ERROR, error })
};

export function AddUser(data) {
    return function (dispatch) {
        dispatch(addUserTriggers.request(data));
    };
}

export const updateUserTriggers = {
    request: request => ({ type: UserActionTypes.UPDATE_REQUEST, request }),
    success: response => ({ type: UserActionTypes.UPDATE_SUCCESS, response }),
    error: error => ({ type: UserActionTypes.FETCH_ERROR, error })
};

export function UpdateUser(data) {
    return function (dispatch) {
        dispatch(updateUserTriggers.request(data));
    };
}

export const deleteUserTriggers = {
    request: request => ({ type: UserActionTypes.DELETE_REQUEST, request }),
    success: response => ({ type: UserActionTypes.DELETE_SUCCESS, response }),
    error: error => ({ type: UserActionTypes.FETCH_ERROR, error })
};

export function DeleteUser(id) {
    return function (dispatch) {
        dispatch(deleteUserTriggers.request(id));
    };
}
