import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IsNullOrEmpty } from '../../helpers/utility';
import NavBar from '../base/NavBar';
import SignIn from './LoginPage';
import * as authActions from "../../store/authentication/auth.actions";
import Footer from '../base/Footer';

class LoginContainer extends Component {
    render() {
        let token = sessionStorage.getItem('x-wallet-payment')?.toString();
        if (!IsNullOrEmpty(token)) {
            return <Redirect to={'/'} />
        }
        return (
            <div>

                <NavBar
                    pageName="Customers"
                    children={
                        <Grid container={true} spacing={0} style={{ maxHeight: 'calc(80vh)' }}>
                            <Grid item={true} xs={12} lg={12}>
                                <SignIn
                                    onLogin={(email, password) => {
                                        this.props.actions.authActions.Login(email, password);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    }
                />
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (response) => ({
    isLoading: response.AuthenticationReducer.isLoading,
});

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            authActions: bindActionCreators(authActions, dispatch)
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer);


