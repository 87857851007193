import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
    },
    icon: {
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            top: -10
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        background: theme.palette.info.main,
        color: 'white'
    }
}));


export default function ReportList(props) {
    const classes = useStyles();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Wallets',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = props.reports.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className={classes.icon}
                            color="secondary"
                            onClick={() => console.log("Hello")}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'creationTime',
            label: 'Created Time',
            options: {
                filter: false,
                sort: true,
            }
        },

        actionRenderer()
    ];

    const data = props.reports.map((item) => {
        return {
            id: item.id,
            creationTime: moment(item.creationTime).format("lll"),
            name: item.name
        };
    });

    return (
        <div className={classes.container}>
            <MUIDataTable
                title={'Report`s List'}
                data={data}
                columns={columns}
                options={{
                    filterType: 'checkbox',
                    selectableRows: 'none',
                    rowsPerPageOptions: [10],
                }}
            />
        </div>
    );
}