import { Grid, List, Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import ContactsIcon from '@material-ui/icons/Contacts';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import theme from '../../theme';
import WalletInfo from './walletInfo';
import CancelIcon from '@material-ui/icons/Cancel';

export default function ViewUserDetails(props) {

    const useStyles = makeStyles({
        root: {
            maxWidth: 'auto',
        },
        media: {
            width: '100%',
            height: 200,
        },
        cancel: {
            position: 'absolute',
            top: 30,
            right: 20,
        }
    });
    const classes = useStyles();
    return (
        <Dialog
            className='model-left-position'
            open={props.open}
            maxWidth='lg'
            onClose={() => props.onClose()}
            TransitionComponent={Slide}
            aria-labelledby="form-dialog-title"
            transitionDuration={500}
            TransitionProps={{
                direction: 'right'
            }}
        >
            <DialogTitle id="alert-dialog-slide-title">
                <ListItem>
                    <ListItemAvatar>
                        <Avatar style={{ backgroundColor: theme.palette.primary.dark }}>
                            <AssignmentIndIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={props.user.tenantName}
                        secondary={props.user.tenantWebsite + ' / ' + props.user.tenantPhone}
                    />
                </ListItem>
                <CancelIcon className={classes.cancel} onClick={() => props.onClose()} color="primary" />
            </DialogTitle>
            <DialogContent>
                <Grid container maxWidth="xl" spacing={2}>
                    <Grid item md={6}>
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: theme.palette.success.dark }}>
                                        <ContactsIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.user.address}
                                    secondary={props.user.city + ' - ' + props.user.zipCode + ',' + props.user.region + ',' + props.user.country}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: theme.palette.success.dark }}>
                                        <ContactPhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.user.primaryContactName}
                                    secondary={props.user.primaryContactEmail + ' / ' + props.user.primaryContactPhone}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar style={{ backgroundColor: theme.palette.success.dark }}>
                                        <ContactPhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={props.user.secondaryContactName}
                                    secondary={props.user.secondaryContactEmail + ' / ' + props.user.secondaryContactPhone}
                                />
                            </ListItem>
                        </List>
                        <h3>Documents</h3>
                        {
                            props.user.documents && props.user.documents.map((x, index) => {
                                return <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={"panel" + index + "a-content"}
                                        id={"panel" + index + "a-header"}
                                    >
                                        <Typography className={classes.heading}>{x.id + ' - ' + x.value}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <img src={x.url} alt="Proof" className="img-responsive" width='100%' />
                                    </AccordionDetails>
                                </Accordion>
                            })
                        }

                    </Grid>
                    <Grid item md={6}>
                        {
                            props.user.wallets && props.user.wallets.map((x) => {
                                return <WalletInfo
                                    wallet={x}
                                    coinPrices={props.coinPrices}
                                    coinBalance={props.coinBalance}
                                />
                            })
                        }
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    );
}