import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './assets/css/toastr.min.css';
import ProtectedRoute from './helpers/PrivateRoute';
import { IsNullOrEmpty } from './helpers/utility';
import DashboardContainer from './pages/dashboard/Dashboard.Container';
import DepositContainer from './pages/deposits/Deposit.Container';
import LoginContainer from './pages/login/Login.Container';
import ReportContainer from './pages/reports/Report.Container';
import settingContainer from './pages/settings/Setting.Container';
import TransactionContainer from './pages/transactions/Transaction.Container';
import UserContainer from './pages/users/User.Container';
import WithdrawContainer from './pages/withdraws/Withdraw.Container';
import theme from './theme';

export default function App(props) {
  let token = sessionStorage.getItem("x-wallet-payment");
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <ProtectedRoute
            exact
            path="/customers"
            component={UserContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/"
            component={DashboardContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/transactions"
            component={TransactionContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/settings"
            component={settingContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/deposits"
            component={DepositContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/reports"
            component={ReportContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/withdraws"
            component={WithdrawContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}
