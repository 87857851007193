import axios from "axios"

export async function getCoinPrices() {
    let allowedCoins = ["TRX", "BTC", "BCH", "LTC", "EOS", "ETH", "USDT", "USDC"];
    return axios({
        method: 'get',
        url: "https://api.alternative.me/v2/ticker/?structure=array",
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((x) => {
        return x.data.data.map((y) => {
            return {
                "name": y.name,
                "symbol": y.symbol,
                "price": y.quotes.USD.price
            }
        }).filter((c) => {
            return allowedCoins.indexOf(c.symbol.toUpperCase()) > -1
        })
    })
}

export async function getCoinBalances(wallets) {
    let requests = wallets.map((wallet) => {

        if (wallet.currency.toLowerCase() === "trx") {
            return axios({
                method: 'get',
                url: "https://api.trongrid.io/v1/accounts/" + wallet.address,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
        }
        else if (wallet.currency.toLowerCase() === "btc") {
            return axios({
                method: 'get',
                url: "https://api.blockcypher.com/v1/btc/main/addrs/" + wallet.address,
                headers: {
                    'Content-Type': 'application/json',

                }
            })
        }
        else if (wallet.currency.toLowerCase() === "bch") {
            return axios({
                method: 'get',
                url: "https://rest.bitcoin.com/v2/address/details/" + wallet.address,
                headers: {
                    'Content-Type': 'application/json',

                }
            })
        }
        else if (wallet.currency.toLowerCase() === "ltc") {
            return axios({
                method: 'get',
                url: "https://api.blockcypher.com/v1/ltc/main/addrs/" + wallet.address,
                headers: {
                    'Content-Type': 'application/json',

                }
            });
        }
        else {
            return null;
        }
    });

    requests = requests.filter((x) => x !== null);

    return await axios.all(requests).then(axios.spread((trx, btc, bch, ltc) => {
        let data = [
            { CoinType: "TRX", CoinBalance: trx.data.data.length > 0 ? (trx.data.data[0].balance / 1000000).toFixed(8) : 0.0.toFixed(8) },
            { CoinType: "BTC", CoinBalance: btc.data.balance.toFixed(8) },
            { CoinType: "BCH", CoinBalance: bch.data.balance.toFixed(8) },
            { CoinType: "LTC", CoinBalance: ltc.data.balance.toFixed(8) },
            { CoinType: "XRP", CoinBalance: 0.0.toFixed(8) }
        ];
        return data;
    })).catch((ex) => {
        let data = [
            { CoinType: "TRX", CoinBalance: 0.0.toFixed(8) },
            { CoinType: "BTC", CoinBalance: 0.0.toFixed(8) },
            { CoinType: "BCH", CoinBalance: 0.0.toFixed(8) },
            { CoinType: "LTC", CoinBalance: 0.0.toFixed(8) },
            { CoinType: "XRP", CoinBalance: 0.0.toFixed(8) }
        ];
        return data;
    })
}
