import { SettingActionTypes } from './setting.model';

export const LoadSettingTriggers = {
    request: request => ({ type: SettingActionTypes.FETCH_SETTING_REQUEST, request }),
    success: response => ({ type: SettingActionTypes.FETCH_SETTING_SUCCESS, response }),
    error: error => ({ type: SettingActionTypes.FETCH_ERROR, error })
};

export function LoadSettings() {
    return function (dispatch) {
        dispatch(LoadSettingTriggers.request());
    };
}

export const UpdateSettingTriggers = {
    request: request => ({ type: SettingActionTypes.UPDATE_SETTING_REQUEST, request }),
    success: response => ({ type: SettingActionTypes.UPDATE_SETTING_SUCCESS, response }),
    error: error => ({ type: SettingActionTypes.FETCH_ERROR, error })
};

export function UpdateSettings(req) {
    return function (dispatch) {
        dispatch(UpdateSettingTriggers.request(req));
    };
}

export const FetchAdminTriggers = {
    request: request => ({ type: SettingActionTypes.FETCH_ADMIN_REQUEST, request }),
    success: response => ({ type: SettingActionTypes.FETCH_ADMIN_SUCCESS, response }),
    error: error => ({ type: SettingActionTypes.FETCH_ERROR, error })
};

export function FetchAdminSettings() {
    return function (dispatch) {
        dispatch(FetchAdminTriggers.request());
    };
}

export const FetchTransactionTriggers = {
    request: request => ({ type: SettingActionTypes.FETCH_TRANSACTIONS_REQUEST, request }),
    success: response => ({ type: SettingActionTypes.FETCH_TRANSACTIONS_SUCCESS, response }),
    error: error => ({ type: SettingActionTypes.FETCH_ERROR, error })
};

export function FetchTransactions(type, uid, startBy) {
    return function (dispatch) {
        dispatch(FetchTransactionTriggers.request({
            type, uid, startBy
        }));
    };
}

export const FetchDepositTriggers = {
    request: request => ({ type: SettingActionTypes.FETCH_DEPOSITS_REQUEST, request }),
    success: response => ({ type: SettingActionTypes.FETCH_DEPOSITS_SUCCESS, response }),
    error: error => ({ type: SettingActionTypes.FETCH_ERROR, error })
};

export function FetchDeposits(type, uid, startBy) {
    return function (dispatch) {
        dispatch(FetchDepositTriggers.request({
            type, uid, startBy
        }));
    };
}


export const FetchWithdrawTriggers = {
    request: request => ({ type: SettingActionTypes.FETCH_WITHDRAWS_REQUEST, request }),
    success: response => ({ type: SettingActionTypes.FETCH_WITHDRAWS_SUCCESS, response }),
    error: error => ({ type: SettingActionTypes.FETCH_ERROR, error })
};

export function FetchWithdraws(type, uid, startBy) {
    return function (dispatch) {
        dispatch(FetchWithdrawTriggers.request({
            type, uid, startBy
        }));
    };
}
