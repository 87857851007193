import { combineReducers } from 'redux';
import { SettingReducer } from './setting/setting.reducers';
import { AuthenticationReducer } from './authentication/auth.reducer';
import { UserReducer } from './user/user.reducers';

const appReducer = combineReducers({
    SettingReducer,
    AuthenticationReducer,
    UserReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;  