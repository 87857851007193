import { call, put, takeEvery } from 'redux-saga/effects';
import * as SettingActions from './setting.actions';
import SettingAPI from './setting.api';
import { SettingActionTypes } from './setting.model';

export function* handleFetchAdminSetting() {
  try {
    const response = yield call(SettingAPI.LoadAdminSetting);
    yield put(SettingActions.FetchAdminTriggers.success(response));
  } catch (e) {
    yield put(SettingActions.FetchAdminTriggers.error(e));
  }
}

export function* handleFetchSetting() {
  try {
    const response = yield call(SettingAPI.LoadSetting);
    yield put(SettingActions.LoadSettingTriggers.success(response));
  } catch (e) {
    yield put(SettingActions.LoadSettingTriggers.error(e));
  }
}

export function* handleUpdateSetting(action) {
  try {
    const response = yield call(SettingAPI.UpdateSetting, action.request);
    yield put(SettingActions.UpdateSettingTriggers.success(response));
  } catch (e) {
    yield put(SettingActions.UpdateSettingTriggers.error(e));
  }
}

export function* handleFetchTransactions(action) {
  try {
    const response = yield call(SettingAPI.FetchTransactions, action.request);
    yield put(SettingActions.FetchTransactionTriggers.success(response));
  } catch (e) {
    yield put(SettingActions.FetchTransactionTriggers.error(e));
  }
}

export function* handleFetchDeposits(action) {
  try {
    const response = yield call(SettingAPI.FetchDeposits, action.request);
    yield put(SettingActions.FetchDepositTriggers.success(response));
  } catch (e) {
    yield put(SettingActions.FetchDepositTriggers.error(e));
  }
}

export function* handleFetchWithdraws(action) {
  try {
    const response = yield call(SettingAPI.FetchWithdraws, action.request);
    yield put(SettingActions.FetchWithdrawTriggers.success(response));
  } catch (e) {
    yield put(SettingActions.FetchWithdrawTriggers.error(e));
  }
}

export function* SettingSaga() {
  yield takeEvery(SettingActionTypes.FETCH_ADMIN_REQUEST, handleFetchAdminSetting);
  yield takeEvery(SettingActionTypes.FETCH_SETTING_REQUEST, handleFetchSetting);
  yield takeEvery(SettingActionTypes.UPDATE_SETTING_REQUEST, handleUpdateSetting);
  yield takeEvery(SettingActionTypes.FETCH_TRANSACTIONS_REQUEST, handleFetchTransactions);
  yield takeEvery(SettingActionTypes.FETCH_DEPOSITS_REQUEST, handleFetchDeposits);
  yield takeEvery(SettingActionTypes.FETCH_WITHDRAWS_REQUEST, handleFetchWithdraws);
}

