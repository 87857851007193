import { Container, Grid, withStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCoinBalances, getCoinPrices } from '../../helpers/coinHelper';
import * as SettingActions from '../../store/setting/setting.actions';
import Footer from '../base/Footer';
import NavBar from '../base/NavBar';
import TitleBar from '../base/TitleBar';
import WalletInfo from '../users/walletInfo';

const styles = theme => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(7),
        right: theme.spacing(2),
    },
});

class DashboardContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coinPrices: [],
            coinBalance: []
        };
    }

    componentDidMount() {
        this.props.actions.SettingActions.LoadSettings();
        this.props.actions.SettingActions.FetchAdminSettings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.adminSetting !== prevProps.adminSetting) {
            getCoinPrices().then((x) => [
                this.setState({
                    coinPrices: x
                })
            ]);
            getCoinBalances(this.props.adminSetting.wallets).then((x) => {
                this.setState({
                    coinBalance: x
                })
            });
        }
    }

    render() {
        return (
            <div>
                <NavBar
                    pageName="Dashboard"
                    children={
                        <Container maxWidth={false} >
                            <TitleBar heading="Dashboard" secondary={"Crypto Users and Wallets"}
                                icon={<HelpOutlineIcon />} />
                            <Grid container spacing={2} >
                                <Grid item md={12}>
                                    <coingecko-coin-price-static-headline-widget coin-ids="tron,bitcoin,litecoin,ethereum,binancecoin" currency="usd" locale="en" background-color="#fbf9f9"></coingecko-coin-price-static-headline-widget>
                                </Grid>
                                {
                                    this.props.adminSetting.wallets && this.props.adminSetting.wallets.map((x) => {
                                        return <Grid item md={6}>
                                            <WalletInfo
                                                key={x.walletId}
                                                wallet={x}
                                                coinPrices={[]}
                                                coinBalance={[]}
                                            />
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Container>
                    }
                />
                <Footer />
            </div >
        );
    }
}


const mapStateToProps = (response) => ({
    isLoading: response.SettingReducer.isLoading,
    setting: response.SettingReducer.setting,
    adminSetting: response.SettingReducer.adminSetting
});

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            SettingActions: bindActionCreators(SettingActions, dispatch),
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DashboardContainer));

