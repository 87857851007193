import {call, put, takeEvery} from 'redux-saga/effects';
import * as UserActions from './user.actions';
import UserAPI from './user.api';
import {UserActionTypes} from './user.model';

export function* handleFetchUsers() {
  try {
    const response = yield call( UserAPI.LoadUsers );
    yield put( UserActions.loadUserTriggers.success( response ) );
  } catch ( e ) {
    yield put( UserActions.loadUserTriggers.error( e ) );
  }
}

export function* handleFetchUser( action ) {
  try {
    const response = yield call( UserAPI.LoadUserDetail, action.request );
    yield put( UserActions.loadUserDetailTriggers.success( response ) );
  } catch ( e ) {
    yield put( UserActions.loadUserDetailTriggers.error( e ) );
  }
}

export function* handleAddUser( action ) {
  try {
    const response = yield call( UserAPI.AddUser, action.request );
    yield put( UserActions.addUserTriggers.success( response ) );
  } catch ( e ) {
    yield put( UserActions.addUserTriggers.error( e ) );
  }
}

export function* handleUpdateUser( action ) {
  try {
    const response = yield call( UserAPI.UpdateUser, action.request );
    yield put( UserActions.updateUserTriggers.success( response ) );
  } catch ( e ) {
    yield put( UserActions.updateUserTriggers.error( e ) );
  }
}

export function* handleDeleteUser( action ) {
  try {
    yield call( UserAPI.DeleteUser, action.request );
    yield put( UserActions.deleteUserTriggers.success( action.request ) );
  } catch ( e ) {
    yield put( UserActions.deleteUserTriggers.error( e ) );
  }
}

export function* UserSaga() {
  yield takeEvery( UserActionTypes.FETCH_REQUEST, handleFetchUsers );
  yield takeEvery( UserActionTypes.ADD_REQUEST, handleAddUser );
  yield takeEvery( UserActionTypes.UPDATE_REQUEST, handleUpdateUser );
  yield takeEvery( UserActionTypes.DELETE_REQUEST, handleDeleteUser );
  yield takeEvery( UserActionTypes.DETAIL_REQUEST, handleFetchUser );
}

