export const SettingActionTypes = {
  FETCH_SETTING_REQUEST: '@@Setting/FETCH_SETTING_REQUEST',
  FETCH_SETTING_SUCCESS: '@@Setting/FETCH_SETTING_SUCCESS',

  UPDATE_SETTING_REQUEST: '@@Setting/UPDATE_SETTING_REQUEST',
  UPDATE_SETTING_SUCCESS: '@@Setting/UPDATE_SETTING_SUCCESS',

  FETCH_ADMIN_REQUEST: '@@Admin/FETCH_ADMIN_REQUEST',
  FETCH_ADMIN_SUCCESS: '@@Admin/FETCH_ADMIN_SUCCESS',

  FETCH_TRANSACTIONS_REQUEST: '@@transactions/FETCH_TRANSACTIONS_REQUEST',
  FETCH_TRANSACTIONS_SUCCESS: '@@transactions/FETCH_TRANSACTIONS_SUCCESS',

  FETCH_DEPOSITS_REQUEST: '@@deposits/FETCH_DEPOSITS_REQUEST',
  FETCH_DEPOSITS_SUCCESS: '@@deposits/FETCH_DEPOSITS_SUCCESS',

  FETCH_WITHDRAWS_REQUEST: '@@withdraws/FETCH_WITHDRAWS_REQUEST',
  FETCH_WITHDRAWS_SUCCESS: '@@withdraws/FETCH_WITHDRAWS_SUCCESS',

  FETCH_ERROR: '@@Setting/FETCH_ERROR',
};
